var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sources-wrapper"
  }, [_c('h1', {
    staticClass: "h6 text-black fw-bold text-dark"
  }, [_vm._v("Redstone sources")]), _c('b-table', {
    attrs: {
      "id": "sources-table",
      "stacked": "md",
      "sort-icon-left": "",
      "busy": _vm.loading,
      "hover": "",
      "items": _vm.sources,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(source) {
        return [_c('div', {
          staticClass: "source-name"
        }, [_c('img', {
          staticClass: "source-logo",
          attrs: {
            "src": source.item.logoURI
          }
        }), _c('span', {
          staticClass: "ml-3"
        }, [_vm._v(" " + _vm._s(source.item.id) + " ")])])];
      }
    }, {
      key: "cell(fetching-success-percentage)",
      fn: function fn(source) {
        return [_c('span', {
          staticClass: "stability-percantage",
          style: {
            color: _vm.getColorForPercentage(source.item['fetching-success-percentage'])
          }
        }, [_vm._v(" " + _vm._s(source.item["fetching-success-percentage"]) + "% ")])];
      }
    }, {
      key: "cell(link)",
      fn: function fn(source) {
        return [_c('a', {
          attrs: {
            "href": source.item.url,
            "target": "_blank"
          }
        }, [_c('i', {
          staticClass: "fa fa-external-link"
        })])];
      }
    }, {
      key: "cell(detailed-report)",
      fn: function fn(source) {
        return [_c('div', {
          staticClass: "source-report-link-container"
        }, [_c('a', {
          attrs: {
            "href": '/#/app/source/' + source.item.id
          }
        }, [_c('span', [_vm._v("View report")]), _c('i', {
          staticClass: "fa fa-angle-right ml-2"
        })])])];
      }
    }])
  }), _vm.showReportsLink ? _c('div', {
    staticClass: "report-source-link"
  }, [_vm._v(" Data source: "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/redstone-finance/redstone-reports"
    }
  }, [_vm._v(" github.com/redstone-finance/redstone-reports ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }