var render = function render(){
  var _vm$provider, _vm$provider$nodes, _vm$provider2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider-details"
  }, [_c('div', {
    staticClass: "provider-info mt-2"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start mt-3 mb-2 provider-values"
  }, [_c('div', {
    staticClass: "pull-model__status"
  }, [_c('span', {
    staticClass: "feeds__status-text mr-4"
  }, [_vm._m(0), _c('strong', [_vm._v(_vm._s(_vm.provider && (_vm$provider = _vm.provider) !== null && _vm$provider !== void 0 && (_vm$provider$nodes = _vm$provider.nodes) !== null && _vm$provider$nodes !== void 0 && _vm$provider$nodes.length ? _vm.provider.nodes.length : "0"))])]), _c('span', {
    staticClass: "feeds__status-text mr-4"
  }, [_vm._m(1), _c('strong', [_vm._v(_vm._s(_vm.provider && (_vm$provider2 = _vm.provider) !== null && _vm$provider2 !== void 0 && _vm$provider2.assetsCount ? _vm.provider.assetsCount : "0"))])]), _c('span', {
    staticClass: "feeds__status-text mr-4"
  }, [_vm._m(2), _c('strong', [_vm._v(_vm._s(_vm.provider && _vm.provider.currentManifest ? _vm.formatInterval(_vm.provider.currentManifest.interval) : undefined))])])]), _c('div', {
    staticClass: "pull-model__pagers text-light fw-normal",
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c('span', {
    staticClass: "feeds__status-text"
  }, [_vm._v(" Per page: "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.perPage,
      expression: "perPage"
    }],
    staticClass: "feeds__select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.perPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onPerPageChange]
    }
  }, _vm._l(_vm.perPageOptions, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0)]), _c('span', {
    staticClass: "feeds__status-text",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" Current page: "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentPage,
      expression: "currentPage"
    }],
    staticClass: "feeds__select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.currentPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onSelectedPageChange]
    }
  }, _vm._l(_vm.availablePages, function (page) {
    return _c('option', {
      key: page,
      domProps: {
        "value": page
      }
    }, [_vm._v(" " + _vm._s(page) + " ")]);
  }), 0)])])])]), _c('hr'), _c('div', [_vm.dataServiceId !== 'redstone-custom-urls-demo' ? _c('b-table', {
    ref: "assetsTable",
    attrs: {
      "id": "assets-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.tokens,
      "fields": _vm.fieldsFiltered,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "filter": _vm.filters,
      "filter-function": _vm.customFilter
    },
    on: {
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('img', {
          staticClass: "token-logo",
          attrs: {
            "src": data.item.logoURI || _vm.findLogoForSource(data.item.name) || _vm.logoPlaceholder
          }
        }), _c('span', {
          staticClass: "token-name ml-3"
        }, [_vm._v(_vm._s(data.item.name || "".concat(data.item.symbol.replace("_FUNDAMENTAL", "").replace("_RATE_PROVIDER", ""), " contract value")))])];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(data) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-truncate d-block",
          attrs: {
            "title": data.item.symbol
          }
        }, [_vm._v(" " + _vm._s(data.item.symbol) + " ")])];
      }
    }, {
      key: "cell(sources)",
      fn: function fn(data) {
        return [_c('div', {
          ref: 'symbols_' + data.item.symbol,
          staticClass: "d-flex source-links-wrapper"
        }, [_c('div', {
          staticClass: "d-flex source-links"
        }, _vm._l(data.item.source, function (source) {
          return _c('a', {
            key: source.symbol,
            staticClass: "source-link mb-2 mb-md-0",
            attrs: {
              "target": "_blank",
              "href": source.url
            }
          }, [_c('img', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "source-logo",
            attrs: {
              "src": source.logoURI || _vm.findLogoForSource(source.name) || _vm.logoPlaceholder,
              "title": source.name
            }
          })]);
        }), 0)])];
      }
    }], null, false, 2426868621)
  }) : _vm._e(), _c('b-pagination', {
    staticClass: "my-3 custom-pagination",
    attrs: {
      "prev-text": _vm.prevIcon,
      "next-text": _vm.nextIcon,
      "limit": "1",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "fill"
    },
    on: {
      "change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "page",
      fn: function fn(_ref) {
        var page = _ref.page;
        return [page === _vm.currentPage ? _c('span', {
          staticClass: "entry-info"
        }, [_vm.totalRows > 0 ? _c('span', [_vm._v(" Showing " + _vm._s(_vm.firstEntry) + " to " + _vm._s(_vm.lastEntry) + " of " + _vm._s(_vm.totalRows) + " entries ")]) : _c('span', [_vm._v("No entries found "), _vm.hasFiltersAndSearch ? _c('span', [_vm._v(" - ")]) : _vm._e(), _vm.hasFiltersAndSearch ? _c('span', {
          staticClass: "clear-filters",
          staticStyle: {
            "pointer-events": "all"
          },
          on: {
            "click": _vm.resetFilters
          }
        }, [_vm._v("Clear filters")]) : _vm._e()])]) : _c('span', [_vm._v(_vm._s(page))])];
      }
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('i', {
    staticClass: "fa fa-server inline"
  }), _vm._v(" Nodes")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('i', {
    staticClass: "fa fa-cube inline"
  }), _vm._v(" Assets")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('i', {
    staticClass: "fa fa-clock-o inline"
  }), _vm._v(" Interval")]);

}]

export { render, staticRenderFns }